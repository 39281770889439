import WebServiceRequest from "../Api/WebServiceRequest";

class ProductCategoriesGetAll extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/GetAllV2");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ProductCategoriesCreate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/Create");
    }
    setParams(data) {
        super.setFormData(data);
    }
}

class ProductCategoriesDelete extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/Delete");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ProductCategoriesUpdate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/Update");
    }
    setParams(data) {
        super.setFormData(data);
    }
}

class ProductCategoriesGetBy extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/GetBy");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ProductCategoriesGet extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/Get");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ProductCategoriesGetByForAdmin extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/GetByForAdmin");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class ProductCategoriesGetSubCategories extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/GetSubCategories");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class ProductCategoriesChangeSelectedStatus extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("ProductCategories/ChangeSelectedStatus");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

// CPG
class CategoryPropertyGroupGetAll extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryPropertyGroup/GetAll");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class CategoryPropertyGroupCreate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryPropertyGroup/Create");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class CategoryPropertyGroupGetBy extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryPropertyGroup/GetBy");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class CategoryPropertyGroupUpdate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryPropertyGroup/Update");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class CategoryPropertyGroupDelete extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryPropertyGroup/Delete");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class CategoryPropertyGroupGet extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryPropertyGroup/Get");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

// CP
class CategoryPropertyGetAll extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryProperty/GetAll");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class CategoryPropertyCreate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryProperties/Create");
    }
    setParams(data) {
        super.setFormData(data);
    }
}
class CategoryPropertyGetBy extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryProperties/GetBy");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class CategoryPropertyUpdate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryProperties/Update");
    }
    setParams(data) {
        super.setFormData(data);
    }
}
class CategoryPropertyDelete extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryProperties/Delete");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class CategoryPropertyGet extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("CategoryProperties/Get");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    ProductCategoriesGetAll,
    ProductCategoriesCreate,
    ProductCategoriesDelete,
    ProductCategoriesUpdate,
    ProductCategoriesGetBy,
    ProductCategoriesGetSubCategories,
    ProductCategoriesGetByForAdmin,
    ProductCategoriesGet,
    ProductCategoriesChangeSelectedStatus,
    CategoryPropertyGroupGetAll,
    CategoryPropertyGroupCreate,
    CategoryPropertyGroupGetBy,
    CategoryPropertyGroupUpdate,
    CategoryPropertyGroupDelete,
    CategoryPropertyGroupGet,
    CategoryPropertyGetAll,
    CategoryPropertyCreate,
    CategoryPropertyGetBy,
    CategoryPropertyUpdate,
    CategoryPropertyDelete,
    CategoryPropertyGet
}