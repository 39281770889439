import WebServiceRequest from "../Api/WebServiceRequest";

class ProductCreate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/Create");
    }
    setParams(data) {
        super.setFormData(data);
    }
}

class ProductUpdate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/Update");
    }
    setParams(data) {
        super.setFormData(data);
    }
}

class ProductGetAll extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/GetAllV2");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ProductSelectedOrDeselectByMainIdForSlider extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/SelectedOrDeselectByMainIdForSlider");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ProductGetBy extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod('GET')
        super.setTag("Products/GetById");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ProductGetById extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/GetBy");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ProductUpdatePriceByMainId extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/UpdatePriceByMainId");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class AbunanceSeasonsGetByProductId extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod("GET")
        super.setTag("AbunanceSeasons/GetByProductId");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class AbunanceCreate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("AbunanceSeasons/Create");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}

class AbunanceDelete extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("AbunanceSeasons/Delete");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    ProductCreate,
    ProductUpdate,
    ProductGetAll,
    ProductGetBy,
    ProductSelectedOrDeselectByMainIdForSlider,
    ProductGetById,
    ProductUpdatePriceByMainId,
    AbunanceSeasonsGetByProductId,
    AbunanceCreate,
    AbunanceDelete
}