<template>
  <div>
    <div v-for="(lang,idx) in languageList" :key="idx" class="d-flex align-items-center justify-content-center mb-1" style="gap: 2px">
      <div class="d-flex align-items-center">
        <feather-icon
          :icon="data.languageId == lang.languageId || doesDataIncludes(lang.languageId, data.otherLanguages) ? 'CheckIcon' : 'XIcon'"
          size="15"
          class="custom-b-margin"
          :class="data.languageId == lang.languageId || doesDataIncludes(lang.languageId, data.otherLanguages) ? 'text-success' : 'text-danger'"
        />
        <span style="font-size: 12px">{{ lang.name }}</span>
        <b-button
          @click="decideAction(lang)"
          variant="primary"
          type="button"
          class="font-bold p-0 text-white bg-primary my-btn p-25"
          size="sm"
        >
          Add/Edit
        </b-button>
      </div>
      <b-button
        :disabled="data.languageId != lang.languageId && !doesDataIncludes(lang.languageId, data.otherLanguages)"
        @click="deletePage(lang)"
        variant="danger"
        type="button"
        class="font-bold p-0 text-white my-btn p-25"
        size="sm"
      >
        Delete
      </b-button>
      <b-button
          v-if="hasProperties"
          :disabled="data.languageId != lang.languageId && !doesDataIncludes(lang.languageId, data.otherLanguages)"
          @click="propertiesPage(lang)"
          variant="warning"
          type="button"
          class="font-bold p-0 text-white my-btn p-25"
          size="sm"
      >
        Properties
      </b-button>
    </div>
  </div>
</template>

<script>
import {GetAllLangs} from "@/libs/Api/Language";

export default {
  emits: ["renderDeleteModal", "getBlogCategoryCreateData"],
  props: {
    data: {
      type: Object,
      required: true,
    },
    languageList: {
      type: Array,
      required: true,
    },
    routerCreateName: {
      type: String, // Create Page of entity name in router
      required: false, //Only required if isBlogCategory props is false
    },
    routerEditName: {
      type: String, // Edit Page of entity name in router
      required: false, //Only required if isBlogCategory props is false
    },
    handelWithModal:{
      type:Boolean,
      default: false
    },
    isBlogCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasProperties: {
      type: Boolean,
      required: false,
      default: false,
    },
    routerPropertiesName: {
      type: String, // Properties Page of entity name in router
      required: false, //Only required if isBlogCategory props is false
    },
  },
  methods: {
    deletePage(lang) {
      if (this.data.languageId === lang.languageId) {
        this.renderDeleteModal({ id: this.data.id });
      } else if (this.doesDataIncludes(lang.languageId, this.data.otherLanguages)) {
        this.renderDeleteModal({
          id: this.findIdInOtherLanguages(lang.languageId, this.data.otherLanguages),
        });
      }
    },
    propertiesPage(lang){
      if (this.data.languageId === lang.languageId) {
        this.$router.push({
          name: this.routerPropertiesName,
          params: {
            id: this.data.id,
          },
        });
      } else if (this.doesDataIncludes(lang.languageId, this.data.otherLanguages)) {
        this.renderDeleteModal({id: this.findIdInOtherLanguages(lang.languageId, this.data.otherLanguages),});
        this.$router.push({
          name: this.routerPropertiesName,
          params: {
            id: this.findIdInOtherLanguages(lang.languageId, this.data.otherLanguages),
          },
        });
      }
    },
    renderDeleteModal(obj) {
      this.$emit("renderDeleteModal", obj);
    },
    decideAction(lang) {
      if (!this.handelWithModal){
        if (this.data.languageId === lang.languageId) {
          this.redirectToEdit(this.data.id);
        } else if (this.doesDataIncludes(lang.languageId, this.data.otherLanguages)) {
          this.redirectToEdit(this.findIdInOtherLanguages(lang.languageId, this.data.otherLanguages));
        } else {
          this.redirectToCreate(this.data.mainId, lang.languageId);
        }
      }else {
        if (this.data.languageId === lang.languageId) {
          this.$emit('renderEditModal', {id:this.data.id,isOtherLanguage:false});
        } else if (this.doesDataIncludes(lang.languageId, this.data.otherLanguages)) {
          this.$emit('renderEditModal',{id:this.findIdInOtherLanguages(lang.languageId, this.data.otherLanguages),isOtherLanguage:true});
        } else {
          this.$emit('renderCreateModal',{mainId:this.data.mainId,languageId:lang.languageId});
        }
      }
    },
    doesDataIncludes(preRoute, data) {
      if (data) {
        let selected = data.find((item) => {
          if (item.languageId == preRoute) {
            return item;
          }
        });
        if (selected) {
          return true;
        }
      }
    },
    findIdInOtherLanguages(preRoute, data) {
      let selected = data.find((item) => {
        if (item.languageId == preRoute) {
          return item;
        }
      });
      if (selected) {
        return selected.objectId;
      }
    },
    redirectToEdit(id) {
      this.$router.push({
        name: this.routerEditName,
        params: {
          id,
        },
      });
    },
    redirectToCreate(mainId, langId) {
      if (!this.isBlogCategory) {
        this.$router.push({
          name: this.routerCreateName,
          params: {
            langId: langId,
            mainId: mainId,
          },
        });
      } else {
        this.$emit("getBlogCategoryCreateData", mainId, langId);
      }
    },
  },
  components: {
    BButton,
  },
};
import { BButton } from "bootstrap-vue";
</script>
<style scoped>
.custom-b-margin {
  margin-bottom: 1px;
}
.my-btn {
  font-size: 13px;
  margin-left: 5px;
}
hr {
  margin: 0 !important;
  padding: 0 !important;
  margin: 3px 0 3px 0 !important;
  border-top: 0 !important;
}
</style>
