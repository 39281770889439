<template>
  <b-overlay :show="isLoading">
    <b-row>
      <!-- Search -->
      <b-col cols="12">
        <b-card>

          <b-col
              class="my-2 p-0"
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search ..."
              />
            </div>
          </b-col>

          <b-table
              class="position-relative"
              :items="productsList"
              responsive
              :fields="myTableColumns"
              primary-key="id"
              show-empty
              bordered
              striped
              empty-text="No Products were found"
          >
            <!-- eslint-disable-next-line -->
            <template #cell(cover)="data">
              <b-media vertical-align="center">
                <b-avatar
                    rounded
                    size="100"
                    :src="`${baseUrl}/${data.item.image.pictureUrl}`"
                    :variant="`success`"
                />
              </b-media>
            </template>

            <template #cell(actions)="data">
              <add-edit-delete-button
                  :data="generateObjectForAddEditRemoveButton(data.item)"
                  :languageList="languageList"
                  :handelWithModal="true"
                  @renderDeleteModal="renderDeleteModal"
                  @renderCreateModal="goToCreatePage"
                  @renderEditModal="goToEditPage"
              ></add-edit-delete-button>
            </template>

            <template #cell(sub)="data">
              <router-link :to="`/apps/products/price/${data.item.id}/${data.item.mainId}`">
                <feather-icon
                    size="20"
                    icon="DollarSignIcon"
                    style="cursor: pointer"
                    class="text-primary"
                />
              </router-link>
            </template>

            <template #cell(season)="data">
              <router-link :to="`/apps/products/abundance/${data.item.id}`">
                <feather-icon
                    size="20"
                    icon="TrendingUpIcon"
                    style="cursor: pointer"
                    class="text-warning"
                />
              </router-link>
            </template>

            <template #cell(prices)="data">
              {{ data.item.prices.price }} {{ data.item.prices.currency }}
            </template>

            <template #cell(isSelected)="data">
              <b-form-checkbox
                  v-model="data.item.isSelected"
                  @change="changeState(data.item)"
                  switch
              />
            </template>
          </b-table>
          <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center py-2"
          >
            <b-pagination
                v-model="pageNumber"
                :total-rows="totalCount"
                :per-page="count"
                first-number
                last-number
                class="mb-0 mt-1"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
        id="delete-modal"
        ok-variant="danger"
        title="Delete product"
        ok-title="Yes"
        cancel-title="Dismiss"
        centered
        @ok="sendDeleteRequest"
    >
      <template v-if="tempProduct">
        <span>Are you sure you want to delete product?</span>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import {GetAllProducts, ProductDeleteRequest, ProductSelectedOrDeselectByMainIdForSlider} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";

export default {
  title: "Products List",
  mounted() {
    this.getAllProductsList();
    this.getAllLanguages();
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllProductsList();
      },
    },
    searchQuery:function (nv,ov){
      this.getAllProductsList()
    },
  },
  data() {
    return {
      baseUrl:Helper.baseUrl,
      languageList:[],
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      categoryName: null,
      tagId: null,
      searchByTitle: null,
      date: null,
      productsList: null,
      searchQuery:'',
      myTableColumns: [
        {
          key: "cover",
          label: "Cover",
        },
        {
          key: "name",
          label: "Title",
        },
        {
          key: "subTitle",
          label: "subTitle",
        },
        {
          key: "prices",
          label: "Last price",
        },
        {
          key: 'isSelected',
          label: 'is selected'
        },
        {
          key: 'sub',
          label: 'Price'
        },
        {
          key: 'season',
          label: 'Season of abundance'
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      tempProduct: null,
    };
  },
  methods: {
    goToCreatePage(data){
      this.$router.push({name:'apps-product-create',query:{mainId:data.mainId,languageId:data.languageId}})
    },
    goToEditPage(data){
      this.$router.push({name:'apps-product-edit-id',params:{id:data.id}})
    },
    async getAllProductsList() {
      try {
        this.isLoading = true;
        let _this = this;
        let qParams = {
          count: _this.count,
          pageNumber: _this.pageNumber,
          languageId:1,
          search:_this.searchQuery
        };
        let productGetAll = new ProductGetAll(_this);
        productGetAll.setParams(qParams);
        await productGetAll.fetch((response) => {
          if (response.isSuccess) {
            _this.productsList = response.data.data;
            _this.totalCount = response.data.totalCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async changeState(item){
      let _this = this;
      _this.showOverlay = true;

      let data = {
        mainId: item.mainId
      }

      let productSelectedOrDeselectByMainIdForSlider = new ProductSelectedOrDeselectByMainIdForSlider(_this);
      productSelectedOrDeselectByMainIdForSlider.setParams(data);
      await productSelectedOrDeselectByMainIdForSlider.fetch(()=>{
        _this.showOverlay = false;
        _this.getAllProductsList();
      },(error)=>{
        _this.showOverlay = false;
        console.log(error)
      })
    },
    generateObjectForAddEditRemoveButton(ProductDetails) {
      return {
        id: ProductDetails.id,
        languageId: ProductDetails.languageId,
        otherLanguages: ProductDetails.otherLanguages || [],
        mainId: ProductDetails.mainId,
      };
    },
    renderDeleteModal(Product) {
      this.tempProduct = JSON.parse(JSON.stringify(Product));
      this.$bvModal.show("delete-modal");
    },
    async sendDeleteRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let ProductDeleteRequest = new ProductDeleteRequest(_this);
        ProductDeleteRequest.setId(_this.tempProduct.id);
        await ProductDeleteRequest.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: "Operation Was Successful",
                icon: "Trash2Icon",
                variant: "success",
                // text: "News Deleted Successfully",
              },
            });
            _this.getAllProductsList();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAllLanguages() {
      try {
        let requestParams = {
          count: 100,
          pageNumber: 1,
        };
        if (this.searchCommand) {
          requestParams["searchCommand"] = this.searchCommand;
        }
        this.isLoading = true;
        let _this = this;
        let getAllLangs = new GetAllLangs(_this);

        getAllLangs.setRequestParam(requestParams);
        await getAllLangs.fetch((response) => {
          if (response.isSuccess) {
            _this.languageList = response.data.languages;
            // _this.totalCount = response.data.languagesCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BFormCheckbox,
    BFormRow,
    BContainer,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BImg,
    BMedia,
    BAvatar,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    AddEditDeleteButton,
  },
};
import {
  BContainer,
  BCard,
  BRow,
  BFormRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BImg,
  BMedia,
  BAvatar,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay, BFormCheckbox,
} from "bootstrap-vue";
import {GetAllLangs} from "@/libs/Api/Language";
import Helper from "@/libs/Helper";
import {ProductGetAll} from "@/libs/Api/Product";
import {ProductCategoriesChangeSelectedStatus} from "@/libs/Api/ProductCategory";
</script>
